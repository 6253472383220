import NestedForm from '@stimulus-components/rails-nested-form'

export default class extends NestedForm {
  static targets = [
    'newVariantStockLocationId',
    'target',
    'template',
    'sourceInput',
    'destinationInput',
    'newVariantOmitIds'
  ]

  connect() {
    super.connect()
    this.sourceLocationId = this.newVariantStockLocationIdTarget.value
    this.destinationLocationId = null
  }

  updateSourceLocation({ target: { value } }) {
    this.newVariantStockLocationIdTarget.value = value
    this.sourceLocationId = value
    this.sourceInputTargets.forEach((input) => {
      input.value = value
    })
  }

  updateDestinationLocation({ target: { value } }) {
    this.destinationLocationId = value
    this.destinationInputTargets.forEach((input) => {
      input.value = value
    })
  }

  updateQuantity({
    target: {
      value,
      dataset: { variantId }
    }
  }) {
    this.element.querySelector(`[data-source-movement]:has(input[value='${variantId}']) [data-source-quantity]`).value =
      -parseInt(value)
  }

  add(e) {
    e.preventDefault()

    const selectedVariant = document.querySelector('input[name="stock_transfer[variant_id]"]:checked')
    const selectedVariantId = selectedVariant.value
    const variantImage = selectedVariant.closest('.search-picker__option').querySelector('.variant-image')
    const variantInfo = selectedVariant.closest('.search-picker__option').querySelector('.variant-info')
    const variantName = variantInfo.querySelector('.variant-name')
    variantName.innerHTML = variantName.innerText
    this.newVariantOmitIdsTarget.value = [this.newVariantOmitIdsTarget.value, selectedVariantId]
      .filter((v) => v?.length)
      .join(',')

    const content = this.templateTarget.innerHTML
      .replace(/NEW_RECORD/g, new Date().getTime().toString())
      .replace(/NEW_RECORD2/g, new Date().getTime().toString())
      .replace(/VARIANT_ID/g, selectedVariantId)
      .replace(/SOURCE_ID/g, this.sourceLocationId)
      .replace(/DESTINATION_ID/g, this.destinationLocationId)
      .replace(/VARIANT_IMAGE/g, variantImage.innerHTML)
      .replace(/VARIANT_INFO/g, variantInfo.innerHTML)

    this.targetTarget.insertAdjacentHTML('beforebegin', content)

    const event = new CustomEvent('rails-nested-form:add', { bubbles: true })
    this.element.dispatchEvent(event)
  }

  remove(e) {
    super.remove(e)
    const variantId = e.target.dataset.variantId
    this.element.querySelector(`[data-source-movement]:has(input[value='${variantId}'])`).remove()
    this.newVariantOmitIdsTarget.value = this.newVariantOmitIdsTarget.value.split(',').filter((v) => v !== variantId).join(',')
  }
}
